<template>
  <div class="image-container">
    <img class="mobile-img" :src="imageUrl" />
  </div>
</template>


<script>
export default {
  name: "ImageComp",
  props: {
    imageUrl: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>
.image-container {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: #efefef;
}
</style>