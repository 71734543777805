<template>
  <div class="timer-bar-container">
    <div v-if="showAddedTime" class="added-time">+{{ addedTime }}s</div>
    <div class="timer-bar" :style="{ width: barWidth + '%' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      required: true, // Duration in seconds
    },
  },
  data() {
    return {
      timeLeft: this.duration,
      intervalId: null,
      addedTime: 0, // Holds the amount of last added time
      showAddedTime: false, // Controls visibility of the added time display
    };
  },
  computed: {
    barWidth() {
      return (this.timeLeft / this.duration) * 100;
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.intervalId);
          this.$emit('timer-ended'); // Emit an event when timer reaches zero
        }
      }, 1000);
    },
    addTime(seconds) {
      this.timeLeft += seconds;
      if (this.timeLeft > this.duration) {
        this.timeLeft = this.duration;
      }

      // Show the added time with animation
      this.addedTime = seconds;
      this.showAddedTime = true;

      // Hide the added time after a brief moment
      setTimeout(() => {
        this.showAddedTime = false;
      }, 1000); // Display time for added seconds
    },
  },
};
</script>

<style scoped>
.timer-bar-container {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.timer-bar {
  height: 100%;
  background-color: #ffa300;
  transition: width 1s linear;
}

.added-time {
  position: absolute;
  top: -25px; /* Position it above the timer bar */
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: bold;
  color: #28a745;
  opacity: 0;
  animation: fadeInOut 1s forwards;
}

/* Animation for fading in and out */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
</style>
