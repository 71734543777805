import { collection, doc, setDoc, orderBy, limit, query, where, getDocs } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

export const createImageDocument = async function (key, data) {
    // Create a document refrencing a location image
    try {
        const db = getFirestore();
        // The random int is a workaround for randomly selecting a document
        const random = getRandomInt(10000000);

        await setDoc(doc(db, 'images', key), { data: data, search: random });
        return key;
    } catch (error) {
        console.error('Error creating document:', error);
    }
};

export const getAllDocs = async function () {
    if(sessionStorage.docs) {
        return JSON.parse(sessionStorage.docs)
    } else {
        const results = [];
        const db = getFirestore();
        const collection_ref = collection(db, 'images')
        let q = query(collection_ref);
        var docs = await getDocs(q);
        console.log(docs);
        docs.forEach((doc) => {
            var obj = {};
            obj.data = doc.data();
            obj.id = doc.id;
            results.push(obj);
        });
        sessionStorage.setItem('docs', JSON.stringify(results));
        return results;
    }
}

export const getRandomImages = async function (imageCount = 1, excludedValues = new Set()) {
   if(!sessionStorage.usedIndexes) {
       sessionStorage.setItem('usedIndexes', JSON.stringify([]));
    }
    // Query the DB for N images.
    // (Optional) pass a set of values to exclude from the query
    const db = getFirestore();
    const collectionRef = collection(db, 'images');

    var usedIndexes = JSON.parse(sessionStorage.usedIndexes);
    const maxQuery = query(collectionRef, orderBy('search', 'desc'), limit(1));
    const maxSnapshot = await getDocs(maxQuery);
    const maxValue = maxSnapshot.docs[0].data().search;
    const resetSessionCount = maxSnapshot.docs[0].data().search - (imageCount + 1);
    const randomValues = new Set(excludedValues);

    while (randomValues.size < imageCount) {
        const randomValue = Math.floor(Math.random() * maxValue) + 1;
        if(!usedIndexes.includes(randomValue)) {
            randomValues.add(randomValue);
            usedIndexes.push(randomValue);
        }
    }

    sessionStorage.usedIndexes = JSON.stringify(usedIndexes);
    if(usedIndexes.length >= resetSessionCount) {
      sessionStorage.setItem('usedIndexes', JSON.stringify([]));
    }

    const randomQuery = query(collectionRef, where('search', 'in', Array.from(randomValues)));
    const randomSnapshot = await getDocs(randomQuery);

    return randomSnapshot.docs.map(doc => doc.data());
};

function getRandomInt(max) {
    // Random number genreator. This is used for randomizing documents
    return Math.floor(Math.random() * max);
}
