<template>
  <div v-if="!gameStarted">
    <br>
    <h1 class="rush-mode-title">Rush Mode</h1>
    <h2> You have 30 seconds to guess many years as you can. </h2>
    <div class="button-overlay">
        <img src="@/assets/play.png" :style="{ width: '100px', height: 'auto' }" @click="beginGuessing()" />
    </div>
  </div>
  <div class="game-container" v-if="!gameCompleted && gameStarted">
    <div class="top">
      <div class="fill-height fill-width" v-if="imageUrl">
        <ZoomistComp v-if="viewportWidth > 768" :imageUrl="imageUrl">
        </ZoomistComp>
        <ImageComp v-else :imageUrl="imageUrl"></ImageComp>
      </div>
      <div v-else class="spinner-container">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <BarTimer v-if="isSmallScreen" ref="timerBar" :duration="30" @timer-ended="handleTimerEnd" />
    <div class="bottom">
      <SliderComp style="margin-bottom: 20px;"></SliderComp>
      <div v-if="userIsGuessing" style="padding-bottom: 20px;">
        <p class="h3 inline year">
          <span id="selectedYear"></span>
        </p>
        <button type="button" @click="guess()" class="inline btn btn-success">
          Confirm
        </button>
      </div>
    </div>
    <CircularTimer v-if="isLargeScreen" ref="timer" :duration="30" @timer-ended="handleTimerEnd"></CircularTimer>
    <transition name="slide-up">
      <div v-if="!userIsGuessing" class="popup-panel bg-primary">
        <h2 v-if="stars >= 0">{{ getEndingMsg() }}</h2>
        <p class="h4 "> {{ description }}</p>
        <div style="text-align: center;">
          <StarComponent class="inline stars" :stars=stars></StarComponent>
          <FireworksComponent class="inline" v-if="stars == 5"></FireworksComponent>
          <button type="button" @click="next()" class="btn btn-success inline">
            Next
          </button>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="gameCompleted">
    <h2> Your Score: <StarComponent :stars=totalScore></StarComponent>
    <h2> Total Points: {{points}} </h2>
    </h2>
    <button type="button" @click="nextRound()" class="btn btn-success btn-lg">
      Next Round
    </button>
  </div>
</template>

<script>
import { getRandomImages } from '../utils/firebase/storage/ImageFireStoreManager.js';
import StarComponent from "../components/Star.vue";
import SliderComp from "../components/SliderComp.vue";
import ZoomistComp from "../components/ZoomistComp.vue";
import ImageComp from "../components/ImageComp.vue";
import FireworksComponent from "../components/Fireworks.vue";
import CircularTimer from '../components/CircularTimer.vue';
import BarTimer from '../components/BarTimer.vue';
import confetti from 'canvas-confetti';


export default {
  name: "PhotoGuessrView",
  components: {
    StarComponent,
    FireworksComponent,
    ZoomistComp,
    SliderComp,
    ImageComp,
    CircularTimer,
    BarTimer
  },
  props: {
    photoView: Object
  },
  data() {
    return {
      userIsGuessing: true,
      currentItemIndex: 0,
      correctGuess: 1982,
      gameCompleted: false,
      selectedItems: [],
      selectedYear: "ad",
      currentItem: {},
      imageUrl: "",
      description: "",
      docs: {},
      stars: -1,
      totalScore: -1,
      height: 0,
      viewportWidth: window.innerWidth,
      isSmallScreen: false,
      isLargeScreen: false,
      points: 0,
      gameStarted: false,
    }
  },
  async mounted() {
    this.docs = await getRandomImages(5);
    this.currentItem = this.docs[0];
    this.correctGuess = this.currentItem.year;
    this.imageUrl = this.currentItem.url;
    this.description = this.currentItem.description;
    window.addEventListener('resize', this.updateViewportWidth);
    if (screen.width < 700) {
      this.isSmallScreen = true;
    } else {
      this.isLargeScreen = true;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateViewportWidth);
  },
  methods: {
    updateViewportWidth() {
      this.viewportWidth = window.innerWidth;
    },
    guess() {
      var year = document.getElementById('selectedYear').innerText;
      this.selectedYear = year;
      var difference = Math.abs(this.correctGuess - this.selectedYear);
      if (difference == 0) {
        this.stars = 5;
        this.launchFireworks();
      } else if (difference < 10) {
        this.stars = 4;
      } else if (difference < 20) {
        this.stars = 3;
      } else if (difference < 30) {
        this.stars = 2;
      } else {
        this.stars = 1;
      }
      if (this.stars == 5) {
        this.points = this.points + 100;
      } else {
        this.points = this.points + this.stars * 10;
      }
      this.increaseTime(this.stars);
      this.currentItem.star = this.stars;
      this.selectedItems.push(this.currentItem);
      this.userIsGuessing = false;
      var slider = document.getElementById('slider');
      slider.noUiSlider.disable();
    },
    handleTimerEnd() {
      console.log("Timer has reached zero!");
      let sum = 0;
      for (let i = 0; i < this.selectedItems.length; i++) {
        sum += this.selectedItems[i].star;
      }
      this.totalScore = Math.round(sum / this.selectedItems.length);
      this.gameCompleted = true;
    },
    increaseTime(time) {
      if (!this.isSmallScreen) {
        this.$refs.timer.addTime(time); // add time to timer
      } else {
        this.$refs.timerBar.addTime(time);
      }
    },
    beginGuessing() {
      this.gameStarted = true;
    },
    launchFireworks() {
      confetti({
        particleCount: 400,
        spread: 70,
        origin: { y: 0.6 },
      });
    },
    async next() {
      this.userIsGuessing = true;
      var slider = document.getElementById('slider');
      slider.noUiSlider.enable();
      setTimeout(() => {
        slider.noUiSlider.reset();
      }, "100");
      this.stars = -1;
      this.currentItemIndex = this.currentItemIndex + 1;
      if ((this.currentItemIndex + 1) % 5 == 1) {
        console.log("pull new images");
        let additionalDocs = await getRandomImages(5);
        console.log(additionalDocs);
        this.docs.push(...additionalDocs);
        console.log(this.docs);
      }
      this.currentItem = this.docs[this.currentItemIndex];
      this.correctGuess = this.currentItem.year;
      this.imageUrl = this.currentItem.url;
      this.description = this.currentItem.description;
    },
    nextRound() {
      this.$router.go(0);
    },
    getEndingMsg() {
      if (this.stars == 5) {
        return `Spot On! The year is ${this.correctGuess}`
      } else if (this.stars == 4) {
        return `${this.selectedYear} is very close! The correct answer is ${this.correctGuess}`
      } else if (this.stars < 4 && this.stars > 0) {
        return `${this.selectedYear} is far off! The correct answer is ${this.correctGuess}`
      }
    }
  },
};
</script>

<style scoped>
.game-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  height: 70%;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.bottom {
  height: 40%;
  width: 100vw;
  min-height: 250px;
  box-shadow: 0 -10px 15px rgba(128, 128, 128, 0.15);
  overflow: hidden;
}

@media (min-width: 768px) {
  .top {
    height: 80%;
  }

  .bottom {
    height: 20%;
  }
}

@media (max-width: 768px) {
  .top {
    height: 60%;
  }

  .bottom {
    height: 40%;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.inline {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.year {
  margin: 0px 15px;
}

.stars {
  margin-right: 15px;
}

.btn {
  min-width: 80px;
}

.popup-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #808d90 !important;
  border-top: 1px white;
  color: white;
  padding: 20px;
  text-align: center;
  z-index: 10;
  opacity: 0.9;
}

/* Slide up animation */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.button-overlay {
  display: inline-block;
  background-color: #e4903f;
  width: fit-content;
  border-radius: 50%; /* Adjust if you want a circular look */
}

.button-overlay img {
  width: 50%; /* Adjust as needed */
  mix-blend-mode: multiply;
  opacity: 0.9; /* Adjust opacity to fine-tune */
}

.rush-mode-title {
  font-family: 'Audiowide', sans-serif;
  font-size: 8vh;
}

@media (max-width: 768px) {
    .rush-mode-title {
     font-size: 5vh;
    }
}

</style>
