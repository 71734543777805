import { createRouter, createWebHistory } from 'vue-router';
import About from '@/views/AboutView'
import Home from '@/views/HomeView'
import LoginView from '@/views/LoginView'
import RegisterView from '@/views/RegisterView'
import SandBox from '@/views/SandBox'
import ClassicView from '@/views/ClassicView'
import RushMode from '@/views/RushModeView.vue';
import { useGtag } from 'vue-gtag-next';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login/',
    name: 'login',
    component: LoginView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/register/',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/about/',
    name: 'about',
    component: About
  },
  {
    path: '/sandbox',
    name: 'sandbox',
    component: SandBox,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/classic',
    name: 'classic',
    component: ClassicView
  },
  {
    path: '/rush',
    name: 'rushMode',
    component: RushMode
  },
  {
    path: '/:all_matches(.*)*',
    component: Home  // TODO create a not-found page
  }
]

//let globalStore;

export default function () {
  //globalStore = store;
  const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
  })

  // Track page views on route change
  router.afterEach((to) => {
    const gtag = useGtag();
    gtag.pageview({
      page_path: to.fullPath,
      page_title: to.name,
    });
  });

  return router
}

//const checkAuth = function(store,to, from, next){
//  return store.dispatch("refreshUser").then(() => {
//    const requireAuth = to.matched[0].meta.requireAuth
//    if (requireAuth == true || requireAuth == undefined) {
//      if (!store.getters.isLoggedIn) {
//        next({ name: "login" })
//      } else {
//        next()
//      }
//    } else if (requireAuth == false && store.getters.isLoggedIn) {
//      // If requireAuth is explicitly false, it means a logged in user cant access this
//      next({ name: "home" })
//    } else {
//      next()
//    }
//  })
//}
