<template>
  <div class="image-container">
    <div class="zoomist-container">
      <!-- image-wrapper is required -->
      <div class=" zoomist-wrapper">
        <!-- image-image is required -->
        <div class="zoomist-image">
          <img v-if="imageUrl" :src="imageUrl" alt="img" />
          <div v-else class="d-flex justify-content-center align-items-center fill-height">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'zoomist/css'
import Zoomist from 'zoomist'

export default {
  name: "ZoomistComp",
  props: {
    imageUrl: {
      type: String,
      required: true
    }
  },
  mounted() {
    let zoomist = new Zoomist('.zoomist-container', {
      maxScale: 3,
      bounds: true,
      slider: true,
      zoomer: true,
      draggable: true,
      pinchable: true,
      zoomRatio: 0.2,
    })
    window.zoomist = zoomist;
  },
}
</script>

<style scoped>
.image-container {
  height: 100%;
}

.zoomist-container {
  width: 100%;
  height: 100%;
  width: 100%;
  display: block;
}

.zoomist-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
}

.zoomist-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>