import "bootstrap/dist/css/bootstrap.css"
import { createApp } from "vue"
import VueGtag from 'vue-gtag-next';
import App from "./App.vue"
import * as bootstrap from "bootstrap/dist/js/bootstrap.js"
import axios from "axios"
import createRouter from "./router"
import store from "./store"

// We just need to import but not use this to initialize firebase
import { firebaseApp } from "./utils/firebase/Init.js";  // eslint-disable-line

const link = document.createElement('link');
link.href = 'https://fonts.googleapis.com/css2?family=Lobster&family=Audiowide&display=swap';
link.rel = 'stylesheet';
document.head.appendChild(link);


const axiosInstance = axios.create({
  withCredentials: true,
})
window.bootstrap = bootstrap;
const router = createRouter(store)
const app = createApp(App).use(store)
app.use(VueGtag, {
  property: {
    id: 'G-4DZGG8T96P', // Replace with your GA4 Measurement ID
  },
});
app.use(router)
app.config.globalProperties.$axios = { ...axiosInstance }
app.mount('#app')

window.newRecipeFlag = false;
