<template>
  <div class="slider-container">
    <div id="slider" class="slider"></div>
  </div>
</template>

<script>
import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default {
  name: "SliderComp",
  components: {

  },
  props: {
    photoView: Object
  },
  async mounted() {
    var slider = document.getElementById('slider');
    var filterPips = function (value) {
      if (screen.width < 700 && value % 20 === 0) {
        return 1
      }
      else if (screen.width > 700 && value % 10 === 0) {
        return 1;
      }
      return 0;
    }
    noUiSlider.create(slider, {
      range: {
        min: 1900,
        max: 2024
      },
      step: 1,
      behaviour: 'snap',
      connect: [false, false],
      start: [1962],
      pips: {
        mode: 'steps',
        density: 0.8196,
        filter: filterPips
      }
    });
    slider.noUiSlider.on('update', function (values) {
      var year = document.getElementById('selectedYear');
      if (year) {
        year.innerText = Math.trunc(values[0]);
      }
    });
  },
}
</script>

<style>
.slider-container {
  padding: 2vh 5vw;
  height: 90px;
}

/* Track (Rail) */
.noUi-target {
  background: linear-gradient(90deg, #d6b48e, #e4cfa3);
  /* Soft sandstone tones */
  border-radius: 10px;
  border: 2px solid #9c7c5c;
  /* Aged bronze effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  /* Subtle shadow for depth */
}

/* Slider Connect */
.noUi-connect {
  background: linear-gradient(90deg, #a67c5b, #d1a97a);
  /* Golden clay tones */
  border-radius: 10px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

/* Handles */
.noUi-handle {
  background: radial-gradient(circle, #d6b48e, #9c7c5c) !important;
  /* Stone-like gradient */
  border: 2px solid #7a5638 !important;
  /* Aged edge */
  height: 30px !important;
  width: 30px !important;
  border-radius: 10% !important;
  /* More irregular, stone-like shape */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 2px 4px rgba(255, 255, 255, 0.2) !important;
}

/* Add an etched symbol on the handle */
.noUi-handle::before {
  font-size: 16px;
  color: #f5e7c8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Handle Hover Effect */
.noUi-handle:hover {
  transform: scale(1.1) rotate(5deg);
  /* Slight tilt for a dynamic effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4), inset 0 2px 4px rgba(255, 255, 255, 0.4);
  background: radial-gradient(circle, #e4cfa3, #a67c5b);
  /* Lighter highlight */
}

/* Handle Active (Clicked) */
.noUi-handle:active {
  transform: scale(1.2);
  background: radial-gradient(circle, #b2967d, #7c5e3b);
  /* Darker pressed look */
}

/* Handle Hover */
.noUi-handle:hover {
  transform: scale(1.1);
  background: #c9a680;
}

/* Tooltip */
.noUi-tooltip {
  background: #8b6a47;
  color: #f5e7c8;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d6b48e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-family: 'Georgia', serif;
  /* Evoke an ancient script */
  padding: 5px;
}

/* Optional: Subtle Etching Effect */
.noUi-target::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 20px 20px;
  opacity: 0.2;
  pointer-events: none;
}

:deep(.noUi-marker) {
  background-color: #495057;

}

:deep(.noUi-value) {
  color: #495057;
}
</style>