<template>
  <nav class="navbar navbar-expand-md navbar-dark">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <img class="snap" src="@/assets/favicon.png" alt="snaps" />
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link play" to="/classic">Classic </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link play" to="/rush">Rush Mode</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link play" to="/about">About Us</router-link>
          </li>
          <!-- <li v-if="!$store.getters.isLoggedIn" class="nav-item">
            <router-link class="nav-link" to="/login">Login</router-link>
          </li>
          <li v-if="$store.getters.isLoggedIn" class="nav-item">
            <a class="nav-link" @click="logout()">Logout</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
export default {
  methods: {
    async logout() {
      await this.$store.dispatch("logout")
      this.$router.push('/')
    }
  },
};
</script>

<style scoped>
.navbar {
  z-index: 9000;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
  background-color: #4d4948;
}

.nav-link {
  padding: 3px 0px;
  color: white;
}

.nav-item {
  font-size: 16px;
}

.play {
  color: #e4903f;
  font-weight: 500;
}

.play:hover {
  color: #e4903f;
}

.snap {
  width: 28px;
  height: 28px;
  margin: 0 12px;
}
</style>
