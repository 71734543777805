<template>
  <div class="no-bs-container">
    <div class="banner-container max-width-container"></div>
    <div class="max-width-container">
      <div class="button-container">
        <div class="game-mode classic" @click="handleClick('classic')">
          <img class="game-mode-img" src="@/assets/classic-turtle.webp" alt="Classic" />
          <div class="classic-title">Classic</div>
        </div>
        <div class="game-mode rush-mode" @click="handleClick('rushMode')">
          <img class="game-mode-img" src="@/assets/rushy-rabbit.webp" alt="Rush Mode" />
          <div class="rush-mode-title">Rush Mode</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LargeButtons',
  methods: {
    handleClick(mode) {
      this.$router.push({ name: mode });
    },
  },
};
</script>

<style scoped>
.no-bs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  background-color: #fff;
}

.banner-container {
  position: absolute;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #d7cdb5;
  background-image: url(@/assets/banner_1600.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.max-width-container {
  max-width: 1600px;
}


.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  z-index: 10;
}

@media (min-width: 768px) {
  .button-container {
    margin-top: 22vh;
    height: 70vh;
    width: 75%;
  }

  .game-mode {
    border: 14px solid #dee2e6;
  }

  .classic-title {
    font-size: 3.9vw;
    left: 20px;
  }

  .rush-mode-title {
    font-size: 3.4vw;
    left: 20px;
  }

  .game-mode-img {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(255, 255, 255, 0) 90%);
  }

  .banner-container {
    mask-image: linear-gradient(to bottom, rgba(215, 205, 181, 1) 20%, rgba(215, 0, 0, .08) 90%);
  }
}

@media (max-width: 768px) {
  .button-container {
    margin-top: 7vh;
    height: 75vh;
    width: 96%;
  }

  .game-mode {
    border: 10px solid #dee2e6;
    margin: auto 2%;
  }

  .classic-title {
    margin: 20px 10px;
    font-size: 7.5vw;
  }

  .rush-mode-title {
    margin: 25px 2px;
    font-size: 6vw;
  }

  .game-mode-img {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(255, 255, 255, 0) 90%);
  }

  .banner-container {
    mask-image: linear-gradient(to bottom, rgba(215, 205, 181, 1) 20%, rgba(215, 0, 0, 0) 95%);
  }
}

.game-mode {
  position: relative;
  width: 48%;
  height: 90%;
  border-radius: 5px;
  transition: border-color .5s ease;
  cursor: pointer;
  z-index: 1;
  background-color: #E1D2A6;
  box-shadow: 0 14px 18px rgba(0, 0, 0, 0.4);
}


.classic:hover {
  border-color: #07a85d;
}

.rush-mode:hover {
  border-color: #ff991c;
}

.rush-mode-title,
.classic-title {
  position: absolute;
  bottom: 0;
  color: black;
  margin-bottom: 10px;
}

.classic-title {
  font-family: 'Lobster', cursive;
}

.rush-mode-title {
  font-family: 'Audiowide', sans-serif;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
