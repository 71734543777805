<template>
  <div class="timer-container">
    <div class="outer-ring">
      <svg class="progress-ring" width="150" height="150">
        <circle class="progress-ring__background" stroke-width="8" fill="transparent" r="64" cx="75" cy="75" />
        <circle class="progress-ring__circle" stroke="#FFA300" stroke-width="8" fill="transparent" r="64" cx="75"
          cy="75" :stroke-dasharray="circumference" :stroke-dashoffset="strokeDashOffset"
          :class="{ animated: isAnimating }" />
      </svg>
      <div v-if="addedTimeVisible" class="added-time">{{ `+${addedTime}` }}</div>
    </div>
    <div class="timer-text">{{ formattedTime }}</div>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      required: true, // Total time in seconds
    },
  },
  data() {
    return {
      timeLeft: this.duration, // Remaining time
      intervalId: null,
      isAnimating: false, // Animation state
      addedTime: 0, // Amount of time added
      addedTimeVisible: false, // Visibility of the added time text
    };
  },
  computed: {
    radius() {
      return 64; // Circle radius
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    strokeDashOffset() {
      const percentage = this.timeLeft / this.duration;
      return this.circumference * (1 - percentage);
    },
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = Math.floor(this.timeLeft % 60);
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.intervalId);
          this.$emit('timer-ended'); // Emit event when timer reaches 0
        }
      }, 1000);
    },
    addTime(seconds) {
      this.isAnimating = true;
      this.addedTime = seconds; // Set added time
      if (this.timeLeft + seconds > 60) {
        this.timeLeft = 60;
      } else {
        this.timeLeft += seconds;
      }
      this.addedTimeVisible = true; // Show added time text

      // Animate the stroke
      this.$nextTick(() => {
        setTimeout(() => {
          this.isAnimating = false; // Reset animation state after a short duration
          this.addedTimeVisible = false; // Hide added time text after displaying
        }, 500); // Duration of animation
      });
    },
  },
};
</script>

<style scoped>
.timer-container {
  position: absolute;
  /* Position absolute for full control */
  top: 20%;
  right: -20%;
  transform: translate(-50%, -50%);
  /* Offset by half its width/height */
  width: 150px;
  /* Fixed width */
  height: 150px;
  /* Fixed height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* Rounded corners */
  z-index: 10000000;
  opacity: 0.5;
}

@media (min-width: 768px) {
  .timer-container {
    top: 20%;
    /* Center vertically */
    right: 10%;
    /* Center horizontally */
  }
}

.outer-ring {
  position: relative;
  width: 150px;
  height: 150px;
  background: radial-gradient(circle, #ffffff 55%, transparent 56%);
  /* Light gradient */
  border-radius: 50%;
}

.progress-ring {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}

.progress-ring__background {
  stroke: #ccc;
  /* Light grey for the background circle */
}

.progress-ring__circle {
  transition: stroke-dashoffset 0.5s ease;
}

.progress-ring__circle.animated {
  animation: pulse 0.5s;
  /* Animation class */
}

@keyframes pulse {
  0% {
    stroke-opacity: 1;
  }

  50% {
    stroke-opacity: 0.5;
  }

  100% {
    stroke-opacity: 1;
  }
}

.timer-text {
  position: absolute;
  font-size: 32px;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  /* Dark text for contrast */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.added-time {
  position: absolute;
  top: 10px;
  /* Positioning at the top of the circle */
  font-size: 16px;
  font-weight: bold;
  color: #FFA300;
  /* McDonald's orange */
  opacity: 0;
  /* Start invisible */
  animation: fadeIn 0.5s forwards;
  /* Fade-in effect */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
    /* Slight upward movement */
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
