<template>
  <div>
    <fieldset class="star-rating">
      <input checked name="rating" value="0" type="radio" id="rating0">
      <label for="rating0">
        <span class="hide-visually">0 Stars</span>
      </label>

      <input name="rating" value="1" type="radio" id="rating1">
      <label for="rating1">
        <span class="hide-visually">1 Star</span>
        <span aria-hidden="true" class="star">★</span>
      </label>

      <input name="rating" value="2" type="radio" id="rating2">
      <label for="rating2">
        <span class="hide-visually">2 Stars</span>
        <span aria-hidden="true" class="star">★</span>
      </label>

      <input name="rating" value="3" type="radio" id="rating3">
      <label for="rating3">
        <span class="hide-visually">3 Stars</span>
        <span aria-hidden="true" class="star" font-size="20px">★</span>
      </label>

      <input name="rating" value="4" type="radio" id="rating4">
      <label for="rating4">
        <span class="hide-visually">4 Stars</span>
        <span aria-hidden="true" class="star">★</span>
      </label>

      <input name="rating" value="5" type="radio" id="rating5">
      <label for="rating5">
        <span aria-hidden="true" class="star">★</span>
      </label>
    </fieldset>
  </div>
</template>

<script>

export default {
  name: "StarComponent",
  data() {
    return {
      photo: "",
      loading: true
    };
  },
  props: {
    stars: Number
  },
  created() {
    if (this.stars > 0) {
      setTimeout(() => {
        this.clickStar(document.getElementById("rating1"));
      }, "400");
    }
    if (this.stars > 1) {
      setTimeout(() => {
        this.clickStar(document.getElementById("rating2"));
      }, "600");
    }
    if (this.stars > 2) {
      setTimeout(() => {
        this.clickStar(document.getElementById("rating3"));
      }, "800");
    }
    if (this.stars > 3) {
      setTimeout(() => {
        this.clickStar(document.getElementById("rating4"));
      }, "1000");
    }
    if (this.stars > 4) {
      setTimeout(() => {
        this.clickStar(document.getElementById("rating5"));
      }, "1200");
    }
  },
  methods: {
    imgLoaded() {
      this.loading = false;
    },
    clickStar(starItem) {
      if (starItem) {
        starItem.click();
      }
    }
  }
};
</script>

<style scoped>
/* this code is lifted from InHuOfficial */
.hide-visually,
.star-rating>input {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px);
  /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%);
  /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap;
  /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

.star-rating {
  display: inline-flex;
  padding: 0;
  border: none;
  pointer-events: none;
}



@media (min-width: 768px) {
  .star-rating {
    font-size: 2.8em;
  }
}

@media (max-width: 768px) {
  .star-rating {
    font-size: 2em;
    vertical-align: middle;
  }
}

.star-rating:focus-within {
  outline: 3px solid #add8e6;
}

.star-rating label {
  cursor: pointer;
  line-height: 1em;
  text-align: center;
}

.star-rating label .star {
  height: 1em;
  width: 1em;
  display: inline-block;
}

.star-rating input:checked~label>span.star {
  -webkit-text-stroke: 2px #000;
  color: #fff;
}

.star-rating span.star,
.star-rating>input:checked+label>span.star {
  color: gold;
  -webkit-text-stroke: 2px #8A7500;
}

.star-rating>input:checked+label>span.star {
  animation: shake 820ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: rotateZ(0);
}

.star-rating>input:checked+label>span.star::before {
  content: '★';
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: currentColor;
  -webkit-text-stroke: 0;
  animation: pulse 820ms cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  transform: scale(1);
  opacity: 0;
}

@media (prefers-reduced-motion) {
  .star-rating>input:checked+label>span.star {
    animation: initial;
  }

  .star-rating>input:checked+label>span.star::before {
    display: none;
  }
}

@keyframes shake {

  10%,
  90% {
    transform: rotateZ(-1deg);
  }

  20%,
  80% {
    transform: rotateZ(2deg);
  }

  30%,
  50%,
  70% {
    transform: rotateZ(-3deg);
  }

  40%,
  60% {
    transform: rotateZ(3deg);
  }
}

@keyframes pulse {
  10% {
    transform: scale(1);
    opacity: 1;
  }

  90% {
    transform: scale(3);
    opacity: 0;
  }
}
</style>