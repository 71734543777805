<template>
  <div class="about-us">
    <header>
      <h1>About Us</h1>
    </header>

    <section class="founders">
      <div class="founder">
        <img src="@/assets/mike.png" alt="Founder 1" />
        <h3>Mike Kasperovich</h3>
        <p>Co-Founder, Software Developer</p>
      </div>
      <div class="founder">
        <img src="@/assets/arian.webp" alt="Founder 2" />
        <h3>Arian Teymourian</h3>
        <p>Co-Founder, Software Developer</p>
      </div>
    </section>

    <section class="donations">
      <h2>We are just a couple of devs, trying to make some cool stuff.</h2>
      <p>
        If you appreciate our work and would like to support us, please consider
        donating to help us continue making the cool stuff.
      </p>
      <!-- PayPal Donation Button -->
      <form action="https://paypal.me/snapguessr?country.x=CA&locale.x=en_US" method="post" target="_blank">
        <input type="hidden" name="business" value="your-paypal-id@example.com" />
        <input type="hidden" name="currency_code" value="USD" />
        <button type="submit" class="paypal-button"><img src="https://www.paypalobjects.com/webstatic/icon/pp258.png"
            alt="PayPal Logo" style="width: 20px; height: 20px" />Donate</button>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped>
.about-us {
  font-family: Arial, sans-serif;
  padding: 20px;
  text-align: center;
  color: #333;
}

header h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.founders {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}

.founder {
  text-align: center;
  max-width: 200px;
}

.founder img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.founder h3 {
  margin-top: 10px;
  font-size: 1.2rem;
}

.donations {
  margin-top: 40px;
}

.paypal-button {
  background-color: #e4903f;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.paypal-button:hover {
  background-color: #005b8c;
}
</style>