<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Sign in</h4>
      </div>
      <div class="modal-body">
        <div class="md-form mb-4 fields">
          <input v-model="email" placeholder="Email Address" @keyup.enter="Login" class="form-control validate" />
        </div>
        <div class="md-form mb-4 fields">
          <input v-model="password" type="password" @keyup.enter="Login" placeholder="Password"
            class="form-control validate" />
        </div>
        <i class="error-msg fields mt-1">{{ error }}</i>
      </div>
      <div class="d-flex justify-content-center mb-4">
        <div class="mb-4">
          <button @click="Login()" type="button" class="btn btn-primary">
            <div v-if="loading" class="spinner-border" role="status"><span class="sr-only"></span></div>
            <div v-if="!loading">Login</div>
          </button>
        </div>
        <div>
          <div>
            <p class="links">Not a member? <router-link to="register">Sign Up</router-link></p>
          </div>
          <div>
            <a class="links" href="url" data-bs-toggle="modal" data-bs-target="#forgotPassModal">Forgot password?</a>
          </div>
          <ForgotPasswordModal />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ForgotPasswordModal from '@/components/ForgotPassword.vue'

export default {
  name: "LoginBox",
  components: {
    ForgotPasswordModal
  },
  data() {
    return {
      error: "",
      email: "",
      password: "",
      loading: false,
    }
  },
  methods: {
    async Login() {
      this.loading = true
      this.error = ""

      try {
        await this.$store.dispatch("login", { email: this.email, password: this.password })
        this.$router.push('/')
      } catch (error) {
        this.error = error
      }
      this.loading = false
    }
  }
};
</script>

<style scoped>
.links {
  float: left;
  margin-left: 2em;
  margin-bottom: 0em;
}

.fields {
  margin-left: 2em;
  margin-right: 2em;
}

.btn:hover {
  background-color: #dddddd;
  color: #36332a;
}
</style>
